import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const AirServiceStudiesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      reportImg: file(relativePath: { eq: "air-service.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Air Service Studies" />
      <Hero
        pageTitle="Air Service Studies"
        breadcrumbText="Services"
        breadcrumbLink="/#services"
      />
      <section className="section-1 highlights image-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <span className="pre-title m-0">
                Scheduled Air Service Analysis
              </span>
              <h2>
                Air Service{" "}
                <span className="featured">
                  <span>Studies</span>
                </span>
              </h2>
              <p className="text-max-800">
                Air service studies provide a detailed examination of the
                required quality of present and future air carrier service. R.A.
                Wiedemann &amp; Associates conducts all types of air service
                improvement studies including Essential Air Service (EAS)
                evaluations, Air Service Feasibility studies, statewide or
                regional Air Service Improvement Studies, and support for
                community air service promotion programs.
              </p>
              <p>
                Increased recognition has been given the value of scheduled air
                service to small communities. The needs of these communities are
                generally such that commuter service may be justified and
                economically feasible while service by larger major or national
                carriers is not. Commuter system plans identify potential points
                of air commuter service, evaluate alternative route structures,
                recommend aircraft types to be used, define appropriate
                schedules, and evaluate the financial implications of such
                service. Typically, these studies are performed either for
                individual airports, states, or multi-state regions.
              </p>
              <Link to="/contact">Contact us about Air Service Studies</Link>
            </div>
            <div className="col-lg-4 mt-5">
              <div className="d-none d-lg-block report-shaddow">
                <Img fluid={data.reportImg.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AirServiceStudiesPage
